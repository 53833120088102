import Swiper from 'swiper';

for (var i = 0; i < 48; i++) {
  var elem = document.createElement("img");
  elem.src = "/images/logos/" + (i + 1) + ".png";
  console.log(elem.src)
  var slide = document.createElement("div");
  slide.classList.add("swiper-slide");
  slide.appendChild(elem);
  (document.getElementsByClassName("swiper-wrapper")[0]).appendChild(slide);
}


var swiper = new Swiper('.swiper-container', {
  slidesPerView: 8,
  spaceBetween: 15,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesOffsetBefore: 50,
  slidesOffsetAfter: 50,
});

var swipers = document.getElementsByClassName('swiper-slide');

for (var i = 0; i < swipers.length; i++) {
  var anchor = swipers[i];
  anchor.onclick = function () {
    var social = document.getElementsByClassName('social-connections');
    social[0].classList.add('horizTranslate');
    var social = document.getElementsByClassName('leader-data');
    social[0].classList.add('horizontalAnimate');
    document.getElementById('video-box').classList.add('do-open');
    document.getElementById('blue-box').style = 'margin-left: 0'
    document.body.setAttribute('style', 'overflow:hidden;');
    document.getElementById('header-container').style = 'background-color: white;';
    var navBtns = document.getElementsByClassName('nav-options')
    for (var i = 0; i < navBtns.length; i++) {
      var anchor = navBtns[i];
      anchor.style = 'color: #00A0DD !important;';
    }
      document.getElementById('video-box').classList.remove('do-close');
  }
}
